import PropTypes from 'prop-types'
import React, { Component } from 'react'

import SeatsLayout from './SeatsLayout'

class Reservation extends Component {
  static propTypes = {
    event: PropTypes.object.isRequired,
    taken: PropTypes.object.isRequired,
  }

  state = {
    step: 1,
    error: null,
    seats: [],
    payment_method: null,
    submitted: false,
  }

  onSeatClick = seat => {
    if (this.state.seats.includes(seat)) {
      this.setState(state => ({ seats: state.seats.filter(s => s != seat) }))
    } else {
      this.setState(state => ({ seats: [...state.seats, seat] }))
    }
  }

  selectPaymentMethod = e => {
    this.setState({ payment_method: e.target.value })
  }

  clearSeats = () => {
    this.setState({ seats: [] })
  }

  submit = async e => {
    e.preventDefault()

    const { event } = this.props
    const { seats, payment_method, submitted } = this.state

    if (submitted) return

    this.setState({ submitted: true })

    try {
      const res = await axios.post(`/events/${event.id}/reservations`, {
        reservation: {
          seats,
          payment_method,
        }
      })

      this.setState({ error: null })
      window.location.href = res.data.path
    } catch (err) {
      this.setState({ error: err.response.data.error, submitted: false })
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }
  }

  render() {
    const { event, taken } = this.props
    const { step, error, seats, payment_method, submitted } = this.state

    return (
      <div className="my-2">
        {error &&
          <div className="d-flex justify-content-center">
            <div className="alert alert-danger alert-dismissible fade show">
              {error}
              <button className="btn-close" data-bs-dismiss="alert" onClick={() => this.setState({ error: null })} />
            </div>
          </div>
        }
        {step == 1 &&
          <div style={{ minWidth: "992px" }}>
            <SeatsLayout taken={taken} seats={seats} onSeatClick={this.onSeatClick} />
            <div className="d-flex justify-content-center my-5">
              <button
                type="button"
                className="col-3 btn btn-success text-light ms-2"
                onClick={() => this.setState({ step: 2 })}
                disabled={seats.length < 1}
              >
                استمرار
              </button>
              <button
                type="reset"
                className="col-3 btn btn-outline-secondary me-2"
                onClick={() => this.clearSeats()}
              >
                حذف
              </button>
            </div>
          </div>
        }
        {step == 2 &&
          <div className="container">
            <form>
              <div className="row m-3">
                <div className="col-md-5 col-sm-12 p-2">
                  <div className="mb-3">
                    <h5>المقاعد المختارة: ({seats.length})</h5>
                    <div className="font-english text-hip-orange fw-bold">
                      {seats.join(", ")}
                    </div>
                  </div>

                  <div className="mb-3">
                    <h5>اختر طريقة الدفع:</h5>

                    <div className="btn-group-vertical m-1" role="group">
                      <input
                        type="radio"
                        className="btn-check"
                        name="payment_method"
                        id="cash"
                        value="cash"
                        checked={payment_method == "cash"}
                        onChange={e => this.selectPaymentMethod(e)}
                      />
                      <label className="btn btn-outline-hip-orange text-end" htmlFor="cash">
                        دفع نقدى
                        <span className="font-english"> | Cash</span>
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        name="payment_method"
                        id="wallet"
                        value="wallet"
                        checked={payment_method == "wallet"}
                        onChange={e => this.selectPaymentMethod(e)}
                      />
                      <label className="btn btn-outline-hip-orange text-end" htmlFor="wallet">
                        محفظة إلكترونية
                        <span className="font-english"> | Wallet</span>
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        name="payment_method"
                        id="instapay"
                        value="instapay"
                        checked={payment_method == "instapay"}
                        onChange={e => this.selectPaymentMethod(e)}
                      />
                      <label className="btn btn-outline-hip-orange text-end" htmlFor="instapay">
                        تحويل بنكى
                        <span className="font-english"> | InstaPay</span>
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-md-7 col-sm-12 p-3 border rounded bg-baltic-sea">
                  <table>
                    <th>
                      <h5>الدفــع</h5>
                    </th>
                    <tr>
                      <td className="ps-2">عدد التذاكـــر: </td>
                      <td>{seats.length}</td>
                    </tr>
                    <tr>
                      <td className="ps-2">سعر التذكـرة: </td>
                      <td>{parseInt(event.ticket_price)} جنيه مصرى</td>
                    </tr>
                    <tr>
                      <td className="ps-2">الإجمالـــــــــــى: </td>
                      <td>{seats.length * event.ticket_price} جنيه مصرى</td>
                    </tr>
                  </table>
                  <hr />
                  <div className="fw-light">
                    {(payment_method == null || payment_method == "cash") &&
                    <>
                      فى حالة اختيارك للدفع بالمحفظة الإلكترونية او التحويل البنكي
                      <br />
                    </>
                    }
                    يرجى إرسال مبلغ
                    <span className="fw-bold text-hip-orange mx-1"> {seats.length * event.ticket_price} جنيه </span>
                    على حساب الرقم
                    <span class="fw-bold text-hip-orange mx-2">01201812309</span>
                    في خلال ساعتين لتجنب الغاء الحجز تلقائياً.
                    <br />
                    ثم إرسال صورة الفاتورة عن طريق
                    <span className="font-english"> WhatsApp </span>
                    على نفس الرقم.
                  </div>
                </div>
              </div>

              <div className="row justify-content-center">
                <button
                  type="submit"
                  className="col-4 col-md-3 btn btn-success text-white ms-2"
                  onClick={e => this.submit(e)}
                >
                  احجز
                </button>
                <button
                  type="button"
                  className="col-4 col-md-3 btn btn-outline-secondary me-2"
                  disabled={submitted}
                  onClick={() => this.setState({ step: 1 })}
                >
                  عودة
                </button>
              </div>
            </form>
          </div>
        }
      </div>
    )
  }
}

export default Reservation
