import PropTypes from 'prop-types'
import React, { Component } from 'react'

const CLOSED_SEAT = "hip-gray";
const EMPTY_SEAT = "hip-green";
const TAKEN_SEAT = "hip-red";
const PENDING_SEAT = "hip-yellow";
const SELECTED_SEAT = "hip-orange";

class SeatsLayout extends Component {
  static propTypes = {
    taken: PropTypes.object.isRequired,
    seats: PropTypes.array,
    onSeatClick: PropTypes.func,
  }

  static defaultProps = {
    seats: [],
  }

  seatLabel = (rowNumber, seatNumber) => {
    const rowLetter = String.fromCharCode('A'.charCodeAt() + rowNumber)
    return `${rowLetter}${seatNumber}`
  }

  renderSeat = label => {
    const { taken, seats, onSeatClick } = this.props

    const isClosed    = taken.closed.includes(label)
    const isPending   = taken.pending.includes(label)
    const isTaken     = taken.confirmed.includes(label)
    const isSelected  = seats?.includes(label)

    return (
      <span
        key={`seat-${label}`}
        className={`seat text-light ${
          isClosed ? `bg-${CLOSED_SEAT}` :
          isPending ? `bg-${PENDING_SEAT}` :
          isTaken ? `bg-${TAKEN_SEAT}` :
          isSelected ? `bg-${SELECTED_SEAT}` : `bg-${EMPTY_SEAT}` }`}
        onClick={() => !isClosed && !isPending && !isTaken && onSeatClick && onSeatClick(label)}
      >
        {label}
      </span>
    )
  }

  mainTopRight = () => {
    const rows = []
    const lengths = [6, 7, 7, 8, 8, 9, 9, 9]

    for(let i = 0; i < 8; i++) {
      let num = 10
      const seats = []

      for(let j = 0; j < lengths[i]; j++) {
        const label = this.seatLabel(i, num)
        seats.push(label)
        num += 2
      }

      rows.push(seats.reverse())
    }

    return (
      <div
        id="main-top-right"
        className="d-flex flex-column justify-content-center align-items-end"
        style={{ transform: "skewY(-15deg)", margin: "0 20px" }}
      >
        {rows.map((seats, idx) =>
          <div key={`row-${idx}`} className="d-flex justify-content-evenly">
            {seats.map(seat => this.renderSeat(seat))}
          </div>
        )}
      </div>
    )
  }

  mainTopMiddle = () => {
    const rows = []

    for(let i = 0; i < 8; i++) {
      let num = 1
      const seats = []
      const length = i % 2 == 0 ? 9 : 8

      for(let j = 0; j < length; j++) {
        const label = this.seatLabel(i, num)

        if (j % 2 == 0) seats.push(label)
        else seats.unshift(label)

        num++
      }
      rows.push(seats)
    }

    return (
      <div
        id="main-top-middle"
        className="d-flex flex-column justify-content-center"
        style={{ transform: "translateY(30px)", margin: "0 20px" }}
      >
        {rows.map((seats, idx) =>
          <div key={`row-${idx}`} className="d-flex justify-content-evenly">
            {seats.map(seat => this.renderSeat(seat))}
          </div>
        )}
      </div>
    )
  }

  mainTopLeft = () => {
    const rows = []
    const lengths = [6, 7, 7, 8, 8, 9, 9, 9]

    for(let i = 0; i < 8; i++) {
      let num = i % 2 == 0 ? 11 : 9
      const seats  = []

      for(let j = 0; j < lengths[i]; j++) {
        const label = this.seatLabel(i, num)
        seats.push(label)
        num += 2
      }
      rows.push(seats)
    }

    return (
      <div
        id="main-top-left"
        className="d-flex flex-column justify-content-center align-items-start"
        style={{ transform: "skewY(15deg)", margin: "0 20px" }}
      >
        {rows.map((seats, idx) =>
          <div key={`row-${idx}`} className="d-flex justify-content-evenly">
            {seats.map(seat => this.renderSeat(seat))}
          </div>
        )}
      </div>
    )
  }

  mainMiddleRight = () => {
    const rows = []
    const lengths = [9, 10, 10, 10, 10, 11, 11, 11, 11]

    for(let i = 0; i < 9; i++) {
      let num = 10
      const seats = []

      for(let j = 0; j < lengths[i]; j++) {
        const label = this.seatLabel(i + 8, num)
        seats.push(label)
        num += 2
      }

      rows.push(seats.reverse())
    }

    return (
      <div
        id="main-middle-right"
        className="d-flex flex-column justify-content-center align-items-end"
        style={{ transform: "skewY(-10deg)", margin: "0 20px" }}
      >
        {rows.map((seats, idx) =>
          <div key={`row-${idx}`} className="d-flex justify-content-evenly">
            {seats.map(seat => this.renderSeat(seat))}
          </div>
        )}
      </div>
    )
  }

  mainMiddle = () => {
    const rows = []

    for(let i = 0; i < 9; i++) {
      let num = 1
      const seats = []

      for(let j = 0; j < 9; j++) {
        const label = this.seatLabel(i + 8, num)

        if (j % 2 == 0) seats.push(label)
        else seats.unshift(label)

        num++
      }
      rows.push(seats)
    }

    return (
      <div
        id="main-middle"
        className="d-flex flex-column justify-content-center"
        style={{ transform: "translateY(25px)", margin: "0 20px" }}
      >
        {rows.map((seats, idx) =>
          <div key={`row-${idx}`} className="d-flex justify-content-evenly">
            {seats.map(seat => this.renderSeat(seat))}
          </div>
        )}
      </div>
    )
  }

  mainMiddleLeft = () => {
    const rows = []
    const lengths = [9, 10, 10, 10, 10, 11, 11, 11, 11]

    for(let i = 0; i < 9; i++) {
      let num = 11
      const seats = []

      for(let j = 0; j < lengths[i]; j++) {
        const label = this.seatLabel(i + 8, num)
        seats.push(label)
        num += 2
      }
      rows.push(seats)
    }

    return (
      <div
        id="main-middle-left"
        className="d-flex flex-column justify-content-center align-items-start"
        style={{ transform: "skewY(10deg)", margin: "0 20px" }}
      >
        {rows.map((seats, idx) =>
          <div key={`row-${idx}`} className="d-flex justify-content-evenly">
            {seats.map(seat => this.renderSeat(seat))}
          </div>
        )}
      </div>
    )
  }

  mainBottomRight = () => {
    let num = 2
    const seats = []

    for(let j = 0; j < 7; j++) {
      const label = this.seatLabel(17, num)
      seats.push(label)
      num += 2
    }

    return (
      <div
        id="main-bottom-right"
        className="d-flex flex-column justify-content-center align-items-end"
        style={{ transform: "skewY(-5deg)", margin: "0 20px" }}
      >
        <div key={`row-1`} className="d-flex justify-content-evenly">
          {seats.reverse().map(seat => this.renderSeat(seat))}
        </div>
      </div>
    )
  }

  mainBottomLeft = () => {
    let num = 1
    const seats = []

    for(let j = 0; j < 7; j++) {
      const label = this.seatLabel(17, num)
      seats.push(label)
      num += 2
    }

    return (
      <div
        id="main-bottom-left"
        className="d-flex flex-column justify-content-center align-items-end"
        style={{ transform: "skewY(5deg)", margin: "0 20px" }}
      >
        <div key={`row-1`} className="d-flex justify-content-evenly">
          {seats.map(seat => this.renderSeat(seat))}
        </div>
      </div>
    )
  }

  upperTopRight = () => {
    const rows = []

    for(let i = 0; i < 3; i++) {
      let num = 8
      const seats = []

      for(let j = 0; j < 14; j++) {
        const label = this.seatLabel(i + 18, num)
        seats.push(label)
        num += 2
      }

      rows.push(seats.reverse())
    }

    return (
      <div
        id="upper-top-right"
        className="d-flex flex-column justify-content-center align-items-end"
        style={{ transform: "skewY(-3deg)", margin: "0 20px" }}
      >
        {rows.map((seats, idx) =>
          <div key={`row-${idx}`} className="d-flex justify-content-evenly">
            {seats.map(seat => this.renderSeat(seat))}
          </div>
        )}
      </div>
    )
  }

  upperTopMiddle = () => {
    const rows = []

    for(let i = 0; i < 3; i++) {
      let num = 1
      const seats = []

      for(let j = 0; j < 7; j++) {
        const label = this.seatLabel(i + 18, num)

        if (j % 2 == 0) seats.push(label)
        else seats.unshift(label)

        num++
      }
      rows.push(seats)
    }

    return (
      <div
        id="upper-top-middle"
        className="d-flex flex-column justify-content-center"
        style={{ transform: "translateY(10px)", margin: "0 20px" }}
      >
        {rows.map((seats, idx) =>
          <div key={`row-${idx}`} className="d-flex justify-content-evenly">
            {seats.map(seat => this.renderSeat(seat))}
          </div>
        )}
      </div>
    )
  }

  upperTopLeft = () => {
    const rows = []

    for(let i = 0; i < 3; i++) {
      let num = 9
      const seats  = []

      for(let j = 0; j < 14; j++) {
        const label = this.seatLabel(i + 18, num)
        seats.push(label)
        num += 2
      }
      rows.push(seats)
    }

    return (
      <div
        id="upper-top-left"
        className="d-flex flex-column justify-content-center align-items-start"
        style={{ transform: "skewY(3deg)", margin: "0 20px" }}
      >
        {rows.map((seats, idx) =>
          <div key={`row-${idx}`} className="d-flex justify-content-evenly">
            {seats.map(seat => this.renderSeat(seat))}
          </div>
        )}
      </div>
    )
  }

  upperBottomRight = () => {
    const rows = []
    const lengths = [15, 15, 15, 16, 16]

    for(let i = 0; i < 5; i++) {
      let num = 2
      const seats = []

      for(let j = 0; j < lengths[i]; j++) {
        const label = this.seatLabel(i + 21, num)
        seats.push(label)
        num += 2
      }

      rows.push(seats.reverse())
    }

    return (
      <div
        id="upper-top-right"
        className="d-flex flex-column justify-content-center align-items-end"
        style={{ transform: "skewY(-3deg)", margin: "0 20px" }}
      >
        {rows.map((seats, idx) =>
          <div key={`row-${idx}`} className="d-flex justify-content-evenly">
            {seats.map(seat => this.renderSeat(seat))}
          </div>
        )}
      </div>
    )
  }

  upperBottomLeft = () => {
    const rows = []
    const lengths = [15, 15, 15, 16, 16]

    for(let i = 0; i < 5; i++) {
      let num = 1
      const seats  = []

      for(let j = 0; j < lengths[i]; j++) {
        const label = this.seatLabel(i + 21, num)
        seats.push(label)
        num += 2
      }
      rows.push(seats)
    }

    return (
      <div
        id="upper-top-left"
        className="d-flex flex-column justify-content-center align-items-start"
        style={{ transform: "skewY(3deg)", margin: "0 20px" }}
      >
        {rows.map((seats, idx) =>
          <div key={`row-${idx}`} className="d-flex justify-content-evenly">
            {seats.map(seat => this.renderSeat(seat))}
          </div>
        )}
      </div>
    )
  }

  render() {
    const { seats } = this.props

    return (
      <div>
        <h2 className="text-center m-auto bg-baltic-sea text-light rounded w-50 py-5">
          الـمـســــــــرح
        </h2>

        <div className="border border-light mt-2">
          <div className="d-flex justify-content-center">
            <div className="d-flex flex-column align-items-end">
              <div className="my-4">{this.mainTopRight()}</div>
              <div className="my-4">{this.mainMiddleRight()}</div>
              <div className="my-4">{this.mainBottomRight()}</div>
            </div>

            <div className="d-flex flex-column align-items-center">
              <div className="my-4">{this.mainTopMiddle()}</div>
              <div className="my-4">{this.mainMiddle()}</div>
            </div>

            <div className="d-flex flex-column align-items-start">
              <div className="my-4">{this.mainTopLeft()}</div>
              <div className="my-4">{this.mainMiddleLeft()}</div>
              <div className="my-4">{this.mainBottomLeft()}</div>
            </div>
          </div>

          <h5 className="text-center">الطابق الرئيسي</h5>
        </div>

        <div className="border border-light mt-2">
          <div className="d-flex justify-content-center my-5">
            {this.upperTopRight()}
            {this.upperTopMiddle()}
            {this.upperTopLeft()}
          </div>

          <div className="d-flex justify-content-center">
            <div className="me-5 pe-3 border-end border-1 border-light">{this.upperBottomRight()}</div>
            <div className="ms-5 ps-3 border-start border-1 border-light">{this.upperBottomLeft()}</div>
          </div>

          <h5 className="text-center mt-3">الطابق العلوي</h5>
        </div>

        <div className="d-flex justify-content-end my-1">
          <div className="d-flex align-items-center ms-3">
            <i className={`fas fa-square text-${EMPTY_SEAT}`}></i>
            <small className={`text-${EMPTY_SEAT} me-1`}>مقعد فارغ</small>
          </div>
          <div className="d-flex align-items-center ms-3">
            <i className={`fas fa-square text-${CLOSED_SEAT}`}></i>
            <small className={`text-${CLOSED_SEAT} me-1`}>مقعد مغلق</small>
          </div>
          <div className="d-flex align-items-center ms-3">
            <i className={`fas fa-square text-${TAKEN_SEAT}`}></i>
            <small className={`text-${TAKEN_SEAT} me-1`}>مقعد محجوز</small>
          </div>
          <div className="d-flex align-items-center ms-3">
            <i className={`fas fa-square text-${PENDING_SEAT}`}></i>
            <small className={`text-${PENDING_SEAT} me-1`}>مقعد حجز معلق</small>
          </div>
          <div className="d-flex align-items-center ms-3">
            <i className={`fas fa-square text-${SELECTED_SEAT}`}></i>
            <small className={`text-${SELECTED_SEAT} me-1`}>المقاعد التي اخترتها</small>
          </div>
        </div>
        {seats.length > 0 &&
          <h5 className="text-center mt-4">المقاعد المختارة:
            <span className="font-english fw-bold"> {seats.join(", ")}</span>
          </h5>
        }
      </div>
    )
  }
}

export default SeatsLayout
