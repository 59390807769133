import axios from "axios"
import ReactOnRails from "react-on-rails"

const header = ReactOnRails.authenticityHeaders()

axios.defaults.headers = header
axios.defaults.headers["Accept"] = "application/json"
axios.defaults.headers["Content-Type"] = "application/json"

export default axios
